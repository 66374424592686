<template>
  <div class="task-list-page">
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :head-height="36"
      :success-duration="1300"
    >
      <template #loading>
        <div class="refresh-box">
          <img
            class="loading-iocn"
            src="../../../../assets/img/quickSend/loading.svg"
            alt="加载"
          />
          <span>加载中</span>
        </div>
      </template>
      <template #success>
        <div class="animate-refresh">
          <span>最新时间</span>
          &nbsp;
          <span>{{ nowDate() }}</span>
        </div>
      </template>
      <van-list
        v-if="taskList.length || refreshing"
        v-model:loading="loading"
        v-model:error="error"
        :finished="finished"
        :immediate-check="false"
        :finished-text="taskList.length == 0 ? '暂无数据' : '没有更多了'"
        error-text="请求失败，点击重新加载"
        @load="onload"
      >
        <!-- <MarketingTaskItem
          v-for="(tsItem, index) in taskList"
          :taskItem="tsItem"
          :index="index"
          :member_userid="this.taskparams.member_userid"
          :customer_userid="this.taskparams.customer_userid"
          @sendSuccess="itemSended"
          :key="index"
        ></MarketingTaskItem> -->
        <div class="task-list">
          <sendListItem
            v-for="(item, index) in taskList"
            :sendItem="item"
            pageType="task"
            :limitNum="5"
            :mediaList="item.annex_content_json"
            :customer_userid="taskparams.customer_userid"
            :member_userid="taskparams.member_userid"
            :chat_id="taskparams.chat_id"
            :index="index"
            cutColor="#F5F5F5"
            :key="index"
            :isSupportEdit="item.is_editable === '1'"
            @sendSuccess="sendSuccess"
            @onEdit="onEdit"
          ></sendListItem>
        </div>
        <span style="display: none">{{ taskList.length }}</span>
      </van-list>
      <!-- <div
        v-if="count == 0 && taskList.length == 0 && finished"
        class="no-data-page"
      > -->
      <div v-else class="no-data-page">
        <div class="no-data-content">
          <img src="@/assets/img/suggestion/task_nodata.svg" alt="暂无数据" />
          <p class="no-data-hint">暂无任务</p>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import { PullRefresh, List } from 'vant'
import sendListItem from '../../../../components/quickSend/sendListItem.vue'
import {
  getCurExternalContact,
  getCurExternalContactPolling,
  getCurExternalChat
} from '../../../../utils/nwechat.js'
import {
  getTaskcount,
  getMarketingTaskList
} from '../../../../service/quickSend.service'
import { obj } from './test'
import moment from 'moment'
export default {
  components: {
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    sendListItem: sendListItem
  },
  props: {
    customer_userid: {
      type: String,
      default() {
        return ''
      }
    }
  },
  name: 'TaskList',
  data() {
    return {
      loading: false,
      taskList: [],
      count: 0,
      finished: false,
      refreshing: false,
      taskparams: {
        member_userid: '',
        customer_userid: '',
        chat_id: '',
        page: 1,
        page_size: 20
      },
      CustomerId: '',
      requestStatus: null,
      pollingIndex: 0
    }
  },
  created() {
    this.init()
    // this.taskList = obj.data.list
    // console.log('列表数据--------', obj.data.list)
  },
  beforeDestroy() {
    clearInterval(this.requestStatus)
  },
  methods: {
    // 初始化页面
    async init() {
      // 成员id
      let userInfo = JSON.parse(localStorage.getItem('vuex'))
      this.taskparams.member_userid = userInfo.user.info.qw_userid
      //  || 'JiangYongWei'

      // 轮询请求客户id
      this.requestStatus = setInterval(() => {
        this.pollingIndex++
        console.log('轮询执行次数', this.pollingIndex)
        if (
          this.taskparams.customer_userid ||
          this.pollingIndex > 3 ||
          this.taskparams.chat_id
        ) {
          clearInterval(this.requestStatus)
          return
        }
        // 本地调试
        // this.taskparams.customer_userid = 'wm1DICCQAAaO7NrD5ttDF1PpUv5uQqIA'
        // this.taskList = []
        // this.taskparams.page = 1
        // this.getList()

        getCurExternalContact()
          .then((res) => {
            // console.log('resres人', res)
            this.taskparams.customer_userid = typeof res === 'object' ? '' : res
            this.taskList = []
            this.taskparams.page = 1
            this.getList()
          })
          .catch(() => {
            this.taskparams.customer_userid = ''
            this.taskList = []
            this.taskparams.page = 1
          })
        getCurExternalChat()
          .then((res) => {
            // console.log('resres群', res)
            this.taskparams.chat_id = res
            this.taskList = []
            this.taskparams.page = 1
            this.getList()
          })
          .catch(() => {
            this.taskparams.chat_id = ''
          })
      }, 300)

      // try {
      //   this.taskparams.customer_userid = await getCurExternalContactPolling()
      //   // console.log(
      //   //   '任务列表获取到的用户id---------',
      //   //   this.taskparams.customer_userid
      //   // )
      // } catch (e) {
      //   console.log(e)
      // } finally {
      //   // console.log('获取id完成-----------')
      //   this.taskList = []
      //   this.taskparams.page = 1
      //   this.getList()
      //   // this.getUnstartCount()
      // }
    },
    async getList() {
      //   console.log('查看参宿和', this.taskparams)

      // 没有获取到客户id或者客户群id的不进行下面的接口请求
      if (!this.taskparams.customer_userid && !this.taskparams.chat_id) {
        this.loading = false
        this.finished = true
        this.count = 0
        // console.log('测试----------', this.finished, this.count)
        clearInterval(this.requestStatus)
        return
      }
      //   console.log('123456789', this.taskparams)
      this.loading = true
      const { data, code } = await getMarketingTaskList(this.taskparams)
      if (code !== 200) {
        this.error = true
        this.loading = false
        this.count = 0
        throw code
        // return
      }
      // 初始状态就是暂无数据
      if (data.list.length === 0 && this.taskList.page === 1) {
        this.loading = false
        this.finished = true
      }
      this.count = data.count || 0
      if (this.taskparams.page === 1) {
        this.taskList = []
      }
      this.taskList = this.taskList.concat(data.list)
      this.loading = false
    },

    // 获取列表信息
    async onload() {
      console.log('接口请求')
      // 翻到最后一页
      if (this.count === this.taskList.length) {
        this.finished = true
      } else {
        this.taskparams.page += 1
        try {
          this.getList()
        } catch (error) {
          this.error = true
          console.log(error)
        } finally {
          return Promise.resolve(true)
        }
      }
    },

    // 下拉刷新
    async onRefresh() {
      this.taskList = []
      this.refreshing = true
      this.taskparams.page = 1
      await this.getList()
      // console.log('刷新被调用了')
      // this.getUnstartCount()
      this.refreshing = false
    },

    // 获取未执行任务数量
    async getUnstartCount() {
      // console.log(
      //   'userid====',
      //   this.taskparams,
      //   this.taskparams.customer_userid
      // )
      // 没有获取到客户id的不进行下面的接口请求
      if (!this.taskparams.customer_userid && !this.taskparams.chat_id) {
        return
      }
      const { data } = await getTaskcount({
        member_userid: this.taskparams.member_userid,
        customer_userid: this.taskparams.customer_userid,
        chat_id: this.taskparams.chat_id
      })
      this.unstartNum = data.count || null
    },
    // 刷新条时间
    nowDate() {
      return moment(new Date()).format('HH:mm:ss')
    },

    // 子组件任务条目发送成功传值
    async sendSuccess(index) {
      console.log('删除index', index)
      this.taskList = []
      //   this.refreshing = true
      this.taskparams.page = 1
      this.$emit('prsendSuccess', index)
      await this.getList()
      //   this.refreshing = false
      //   this.taskList.splice(index, 1)
      if (this.taskList.length == 0) {
        this.count = 0
        this.finished = true
      }
    },

    // 超过18, 前 8 后 8 中间...
    formatTitle(txt) {
      if (txt.length < 19) {
        return txt
      } else {
        let len = txt.length
        let start = txt.substring(0, 8)
        let end = txt.substring(len - 8, len)
        return `${start}...${end}`
      }
    },
    // 编辑事件
    onEdit(data = {}) {
      console.log('wozhendefule', data)
      const { customer_userid, member_userid, chat_id } = this.taskparams
      const { id, task_id, task_send_record_son_id } = data
      this.$router.push({
        name: 'taskEdit',
        query: {
          id,
          task_id,
          task_send_record_son_id,
          customer_userid,
          member_userid,
          chat_id
        }
      })
    }
  }
  // watch: {
  //   customer_userid(val) {
  //     if (val) {
  //       this.init()
  //     }
  //   }
  // }
}
</script>
<style lang="less" scoped>
.task-list-page {
  height: calc(100vh - 66px);
  background-color: #f5f5f5;
  overflow-y: scroll;
  .task-list {
    padding: 20px 15px;
  }
  // .no-data-page {
  //   margin-top: 1px;
  //   height: calc(100vh - 72px);
  //   background-color: #f5f5f5;
  //   display: flex;
  //   justify-content: center;
  //   // padding-top: 200px;
  //   align-items: center;
  //   .no-data-content {
  //     margin-top: -65px;
  //     img {
  //       width: 110px;
  //       height: 118px;
  //     }
  //     .no-data-hint {
  //       margin-top: 13px;
  //       font-size: 14px;
  //       line-height: 20px;
  //       color: #909399;
  //       text-align: center;
  //     }
  //   }
  // }
}
</style>
